<template>
  <div class="contact-section">
    <WidgetsPracticalInfoWidgetSectionContent :title="section.title">
      <address class="contact-info">
        <div class="address" v-html="section.address" />
        <p v-if="section.phone_number" class="phone-number">
          {{ t('helpline') }}:
          <br />
          <a :href="`tel:${section.phone_number.replace(/\s/g, '')}`">
            {{ section.phone_number }}
          </a>
        </p>
        <a class="email-address" :href="`mailto:${section.email_address}`">
          {{ section.email_address }}
        </a>
      </address>
    </WidgetsPracticalInfoWidgetSectionContent>
    <WidgetsPracticalInfoWidgetSocialMedia v-if="section.with_social_media" />
  </div>
</template>

<script lang="ts" setup>
import type { PublicContactSubsection } from '~/service/__generated-api'

defineProps<{
  section: PublicContactSubsection
}>()

const { t } = useI18n()
</script>

<style scoped lang="scss">
.contact-section {
  scroll-margin-top: calc($nav-height-scrolled + $practical-info-title-box);

  @include media-up(md) {
    scroll-margin-top: $nav-height-scrolled;
  }
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: rem(32px);

  padding-bottom: rem(25px);
  font-style: normal;

  @include media-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, rem(267px)));
    gap: rem(27px) rem(46px);
  }

  @include media-up(md) {
    padding-bottom: rem(56px);
  }
  @include media-up(lg) {
    padding-bottom: rem(22px);
  }

  @include media-up(xl) {
    padding-bottom: rem(122px);
  }
}

.address,
.phone-number {
  @include font-size(
    (
      xs: 21px,
      md: 24px,
      lg: 23px,
      xl: 28px
    ),
    1.34
  );
  letter-spacing: -0.025em;
}

.email-address {
  max-width: rem(219px);
  font-size: rem(23px);
  font-style: italic;
  font-weight: 500;
  line-height: rem(28px);
  letter-spacing: -0.025em;

  @include media-up(md) {
    max-width: rem(228px);
    font-size: rem(24px);
    line-height: 1.25;
  }

  @include media-up(lg) {
    grid-column: 2/3;
    max-width: unset;
    font-size: rem(21px);
    line-height: rem(32px);
  }

  @include media-up(xl) {
    font-size: rem(24px);
  }
}
</style>
